import { render, staticRenderFns } from "./EmployeeLogs.vue?vue&type=template&id=065bdd18&"
import script from "./EmployeeLogs.vue?vue&type=script&lang=js&"
export * from "./EmployeeLogs.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeLogs.vue?vue&type=style&index=0&id=065bdd18&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports